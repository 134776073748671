.menus {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 960px;
  margin: auto;
}

.menu {
  display: flex;
  flex-direction: column;
  width: calc((100% - ( 2 * 104px )) / 3 );
  margin-right: 104px;
  text-align: center;

  @media screen and (min-width: $mediumScreen + 1px) {
    &:nth-child(3n) {
      margin-right: 0;
    }

    &:nth-child(n + 4) {
      margin-top: 104px;
    }
  }

  @media screen and (max-width: $mediumScreen) {
    width: calc((100% - 42px) / 2);
    margin-right: 42px;

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:nth-child(n + 3) {
      margin-top: 42px;
    }
  }

  @media screen and (max-width: $smallScreen) {
    width: 100%;
    margin-right: 0;

    &:nth-child(n + 2) {
      margin-top: 36px;
    }
  }
}

.menu-image {
  display: block;
  width: auto;
  height: auto;
  max-width: 250px;
  align-self: center;
}

.menu-pdf-link {
  text-decoration: underline;
  color: $linkColor;

  &:hover,
  &:focus {
    color: darken($linkColor, 8%);
  }
}
