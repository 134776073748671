header {
  .header-facebook {
    display: inline-block;
    float: right;
    margin: 7px 0 0 20px;
    height: 24px;
    width: 24px;
    background: url("../images/h-facebook.svg") no-repeat;

    @media screen and (max-width: 1023px) {
      margin-top: 0;
      margin-right: 10px;
      margin-left: auto;
    }
  }

  .desktop-header {
    display: none;
    background: $white;
    position: fixed;
    z-index: 9999;
    width: 100%;

    @media screen and (min-width: $mediumScreen) {
      display: block;
    }

    .top-header {
      background: $black;
      text-align: right;
      padding: 0 20px;

      @media screen and (min-width: $largeScreen) {
        padding: 0;
      }

      .top-header-menu {
        @include container;
        @include clearfix;

        max-width: 1200px;

        a {
          color: $white;
          text-transform: uppercase;
          margin-right: 20px;
          font-size: 12px;

          @media (min-width: 1024px) and (max-width:1070px) {
            margin-right: 15px;
          }
        }

        .header-phone {
          display: inline-block;
          margin: 0;
          padding: 10px 12px;
          font-size: 16px;
          color: $white;
          font-family: $latoFont;
          font-weight: 500;
          background: $cyan;
        }
      }
    }

    .main-header {
      @include container;

      padding: 0 20px;
      max-width: 1200px;

      @media screen and (min-width: $largeScreen) {
        padding: 0;
      }

      .header-logo {
        padding: 25px 0;
        display: inline-block;

        img {
          transition: all 0.3s ease-in-out;
          max-width: 163px;

          @media (min-width: 1024px) and (max-width: 1035px) {
            max-width: 140px;
          }
        }
      }

      .header-menu {
        padding: 50px 0 0;
        transition: padding 0.3s ease-in-out;

        a {
          font-size: 16px;
          text-transform: uppercase;
          margin-right: 20px;
          position: relative;

          @media (max-width: 1090px) {
            font-size: 14px;
          }

          &:last-child {
            margin-right: 0;
          }

          &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            background: $cyan;
            opacity: 0.5;
            width: 0;
            height: 4px;
            bottom: -44px;
            transition: width 0.35s ease-in-out;
          }

          &:before {
            left: -10px;
          }

          &:after {
            right: -10px;
          }

          &:hover, &.current {
            &:before, &:after {
              width: calc(100% + 18px);
            }
          }
        }
      }
    }

    &.fixed-state {
      .main-header {
        .header-logo {
          img {
            max-width: 120px;
          }
        }

        .header-menu {
          padding-top: 40px;

          a:before,
          a:after {
            bottom: -37px;
          }
        }
      }
    }
  }

  .mobile-menu {
    display: block;
    position: fixed;
    z-index: 9999;
    width: 100%;
    background: $white;
    box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.2);
    max-height: 100vh;
    overflow: scroll;

    @media screen and (min-width: $mediumScreen) {
      display: none;
    }

    .menu-wrapper {
      padding: 10px 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      .header-logo {
        display: inline-block;
        padding: 7.5px 15px;

        img {
          max-width: 160px;

          @media screen and (max-width: 1023px) {
            max-width: 100px;
          }
        }
      }
    }

    .header-nav {
      display: none;

      .main-menu {
        .menu-links {
          display: block;
          padding: 20px 20px 10px;
          font-size: 20px;
          text-transform: uppercase;
          border-bottom: 1px solid $grey;

          &:first-of-type {
            box-shadow: inset 0 10px 15px -10px rgba(0, 0, 0, 0.2);
          }
        }
      }

      .sub-menu {
        background: $lightGrey;
        padding: 20px;

        .menu-links {
          display: block;
          font-size: 16px;
          text-transform: uppercase;
          margin: 10px 0;

          &:first-of-type {
            margin-top: 0;
          }

          &:last-of-type {
            margin: 0;
          }
        }
      }

      .header-phone {
        display: block;
        width: 100%;
        font-size: 20px;
        background: $cyan;
        margin: 0;
        padding: 15px 0;
        text-align: center;
        color: $white;
        font-family: $latoFont;
      }
    }
  }
}
