@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}

@mixin dropshadow {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

@function calculateRem($size) {
  $remSize: $size / 16px;

  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin container {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

@mixin section-padding {
  padding: 80px 40px;

  @media screen and (max-width: 1023px) {
    padding: 40px 20px;
  }
}

@mixin clearfix {
  /* TODO: / HACK / DECIDE ... overflow: hidden; ? */
  clear: both;
  overflow: hidden;

  &.absolute-clearfix {
    overflow: visible;

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
}

@mixin basic-list {
  ul, ol {
    list-style: none;
    counter-reset: list;
    padding-left: 0;
  }

  li {
    position: relative;
    padding-left: 16px;
    counter-increment: list;
    line-height: 28px;

    &:before {
      content: counter(list, square);
      position: absolute;
      left: 0;
      top: 1px;
      color: $bulletListColor;
      font-size: 14px;
    }
  }
}

@mixin icomoon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin btn-download {
  @extend .button;

  text-align: center;
  max-width: fit-content;
  margin: 30px 0 26px;
  position: relative;
  font-size: 14px;
  letter-spacing: 0.35px;
  line-height: 14px;
  padding: 0;
  display: flex;
  flex-direction: row;

  .label {
    padding: 12px 15px;
    line-height: 22px;
  }

  .icon-Icon-download {
    margin-left: auto;
    justify-content: center;
    padding: 12px 15px;
    border-left: 1px solid $white;
    display: flex;
    flex-direction: column;
  }
}

@mixin lines-separator {
  position: relative;
  overflow: visible;

  &:before {
    content: '';
    border-top: 2px solid $cyan;
    border-bottom: 2px solid $cyan;
    width: 46px;
    height: 11px;
    position: absolute;
    top: -8px;
    left: calc((100% - 46px) / 2);
  }
}