.generic-content {
  @include clearfix;

  h1, h2, h3 {
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  a {
    text-decoration: underline;
    color: $linkColor;

    &:hover,
    &:focus {
      color: darken($linkColor, 8%);
    }
  }

  p, a {
    @include font-size(18px);

    line-height: 24px;
    margin: 20px 0;

    img {
      max-width: 100%;
    }
  }

  ul, ol {
    list-style: none;
    counter-reset: list;
    padding-left: 0;

    li {
      @include font-size(18px);

      position: relative;
      padding-left: 16px;
      counter-increment: list;

      &:before {
        content: counter(list, square);
        position: absolute;
        left: 0;
        top: 1px;
        color: $bulletListColor;
        font-size: 14px;
      }
    }
  }

  section {
    @include container;

    margin: 40px auto 0;
    max-width: 960px;
    padding-left: 20px;
    padding-right: 20px;

    &.concours,
    &.forfaits {
      text-align: center;
    }

    &:first-child {
      margin-top: 0;
      padding-top: 40px;

      @media screen and (min-width: $smallScreen) {
        padding-top: 60px;
      }

      @media screen and (min-width: $largeScreen) {
        padding-top: 80px;
      }
    }

    &:last-child {
      padding-bottom: 40px;

      @media screen and (min-width: $smallScreen) {
        padding-bottom: 60px;
      }

      @media screen and (min-width: $largeScreen) {
        padding-bottom: 80px;
      }
    }

    &.souper-spectacle {
      margin-top: 134px;
    }
  }

  .generic-gallery {
    margin-top: 60px;

    a {
      float: left;
      position: relative;
      overflow: hidden;
      max-height: 250px;

      &:last-child, &:nth-child(4n+4) {
        margin-right: 0;
      }

      @media screen and (min-width: $smallScreen) {
        width: calc((100% - 126px) / 4);
        margin: 0 42px 42px 0;
      }

      img {
        vertical-align: middle;
        width: 100%;
        transition: all 0.3s ease-in-out;
        transform: scale(1);
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }

  .generic-label {
    position: relative;
    width: 100%;
    background: $lightGrey;

    &.pdf {
      margin-bottom: 50px;
      padding: 20px 20px 15px;

      @media screen and (min-width: $smallScreen) {
        margin-bottom: 20px;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 45px;
        background: url("../images/pdf-picto.svg") $cyan no-repeat center right 10px;
        transition: all 0.3s ease-in-out;
        pointer-events: none;

        @media screen and (min-width: $smallScreen) {
          width: 65px;
          background-position: center;
        }
      }

      a {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        text-decoration: none;
      }

      p {
        margin: 0;
        color: $black;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;

        @media screen and (min-width: $smallScreen) {
          @include font-size(20px);
        }
      }

      &:hover {
        p {
          color: $linkColor;
        }

        &:after {
          background-color: darken($cyan, 8%);
        }
      }
    }

    &.big-block, &.career {
      margin-bottom: 40px;

      img {
        width: 100%;

        @media screen and (min-width: $smallScreen) {
          max-width: 250px;
          padding: 30px 0 0 30px;
        }

        @media screen and (min-width: $mediumScreen) {
          max-width: 200px;
        }
      }

      .g-label-content {
        padding: 30px 30px 0;

        @media screen and (min-width: $smallScreen) {
          width: calc(100% - 250px);
        }

        @media screen and (min-width: $mediumScreen) {
          width: calc(100% - 200px);
        }

        .title-3 {
          font-weight: 500;
          margin: 0;
        }

        .g-label-text {
          margin: 10px 0 20px;
        }

        .button {
          position: relative;
          margin: 0;
          color: $white;
          font-size: 14px;
          padding: 12px 40px 8px 15px;
          margin-bottom: 30px;
          text-decoration: none;
          background-image: url("../images/pdf-picto.svg");
          background-repeat: no-repeat;
          background-position: center right 13px;
          background-size: 19px 20px;

          @media screen and (min-width: $smallScreen) {
            padding: 12px 55px 8px 25px;
            margin: 0 0 20px 0;
          }
        }
      }
    }

    &.career {
      padding: 30px;

      .g-label-content {
        padding: 0;
        width: 100%;

        .button {
          background-image: none;
          padding: 12px 10px 8px;
          margin: 0;
          text-align: center;

          @media screen and (min-width: $smallScreen) {
            padding: 12px 25px 8px;
            margin: 0;
          }
        }
      }
    }

    &.spontaneous {
      background: transparent;
      border: 2px dotted $cyan;
    }

    &.faq {
      @include container;

      padding: 0 20px;
      background: transparent;
      margin-bottom: 20px;

      .faq-title {
        @include font-size(18px);

        position: relative;
        display: block;
        padding: 15px 60px 10px 15px;
        margin-bottom: 0;
        text-transform: uppercase;
        font-weight: 500;
        background: $lightGrey;
        cursor: pointer;

        @media screen and (min-width: $smallScreen) {
          padding: 15px 15px 10px;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          width: 48px;
          height: 100%;
          background: url("../images/plus-picto.svg") $cyan no-repeat center;
          transition: all 0.3s ease-in-out;
          pointer-events: none;
        }

        &:hover {
          &:after {
            background-color: darken($cyan, 8%);
          }
        }
      }

      &.opened {
        .faq-title {
          &:after {
            background-image: url("../images/minus-picto.svg");
          }
        }
      }

      .faq-content {
        border: 2px dotted $cyan;
        padding: 30px;
        border-top: 0;
        display: none;

        p, a, h1, h2, h3, h4, h5 {
          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  //CONTEST FORM
  img.fullwidth {
    width: 100%;
  }

  .contest-form {
    @include dropshadow;

    max-width: 960px;
    margin: 60px auto 0;
    padding: 30px;
    text-align: center;

    @media screen and (min-width: $smallScreen) {
      padding: 60px;
    }

    .title-1 {
      margin-bottom: 60px;

      @include font-size(28px);

      @media screen and (min-width: $smallScreen) {
        @include font-size(32px);
      }
    }

    .input-container {
      margin-bottom: 0px;

      @media screen and (min-width: $smallScreen) {
        margin-bottom: 40px;
      }

      input {
        position: relative;
        float: left;
        width: 100%;
        padding: 5px 0;
        margin-bottom: 30px;
        border: 0;
        border-bottom: 1px solid $black;
        font-size: 20px;
        transition: all 0.3s ease-in-out;

        &:last-child {
          margin-right: 0;
        }

        &:focus {
          border-bottom-color: $cyan;
        }

        @media screen and (min-width: $smallScreen) {
          width: calc(50% - 20px);
          margin: 0 40px 0 0;
        }

        &.parsley-error {
          border-bottom: 2px solid $red;
          color: $red;
        }
      }
    }

    .button {
      float: right;
      border: 0;
      margin: 0;
      font-size: 16px;
      padding-bottom: 8px;
    }

    .success {
      color: #137f13;
      font-size: 24px;
      font-weight: 600;
      text-transform: uppercase;
      padding-top: 10px;
      display: none;
    }
  }

  &.error-page {
    padding-top: 50px;

    @media screen and (min-width: $smallScreen) {
      padding-top: 123px;
    }

    .title-2 {
      text-transform: none;
    }

    .button {
      text-decoration: none;
      color: $white;
      font-size: 14px;
    }
  }
}
