.footer-bottom {
  @include section-padding;

  background: #000;
  text-align: center;

  @media screen and (min-width: $mediumScreen) {
    text-align: left;
  }

  p, h3 {
    color: $white;
  }

  .footer-link {
    color: $cyan;

    &:hover,
    &:focus {
      color: darken($cyan, 20%);
    }
  }

  .footer-infos {
    @include container;

    display: flex;
    flex-direction: row;

    // max-width: 1200px;
    @media screen and (max-width: 1023px) {
      flex-direction: column;
      margin-bottom: 40px;
    }
  }

  .footer-about,
  .footer-address,
  .footer-contact,
  .footer-social {
    width: 25%;
    margin-right: 40px;

    @media screen and (max-width: 1023px) {
      width: 100%;
      margin-bottom: 24px;
    }
  }

  .footer-social {
    margin-right: 0;

    @media screen and (max-width: 1023px) {
      margin-bottom: 0;
    }
  }

  .f-logo {
    max-width: 150px;
  }

  .fb-like {
    display: block;
    margin: 15px 0;
  }
}