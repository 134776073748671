.search-form {
  input {
    border: 0;
    padding: 20px;
    font-size: 18px;
    width: 100%;
    background: url("../images/search-picto.svg") no-repeat center right 20px;
  }
}

.show-search-container {
  @include container;

  text-align: center;
  position: relative;
  margin-top: -160px;
  color: $white;
  padding: 0 20px;

  @media screen and (max-width: $smallScreen) {
    margin-top: -220px;
  }

  p {
    font-size: 18px;
    margin: 20px 0;
  }

  .keyword-search {
    width: 100%;
    padding-bottom: 5px;
    margin-bottom: 20px;
    font-size: 18px;
    border: 0;
    border-bottom: 1px solid $white;
    background: transparent;
    color: $white;

    @media screen and (min-width: $smallScreen) {
      width: calc(100% - 174px);
      margin-bottom: 0;
    }

    @media screen and (min-width: $mediumScreen) {
      margin-right: 20px;
    }
  }

  .keyword-submit {
    border: 0;
    font-size: 14px;
    padding: 14px 25px 10px;
    margin: 0;
  }

  .filters-holder {
    @include clearfix;

    margin: 30px 0;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 879px) {
      flex-direction: column;
    }

    label {
      color: $black;
      text-transform: uppercase;
      font-family: NeutraDemi;
      padding: 10px 15px 10px 0;
      margin-right: 15px;
      width: 20%;
      text-align: left;

      @media screen and (max-width: 879px) {
        width: 100%;
        text-align: center;
      }
    }

    .select-wrapper {
      overflow: hidden;
      width: 100%;
      position: relative;
      display: flex;

      &:after {
        content: '';
        background: url("../images/arrow-white.svg") $cyan no-repeat center;
        position: absolute;
        display: block;
        right: 0;
        top: 0;
        width: 42px;
        height: 100%;
        pointer-events: none;
        transform: rotate(90deg);
      }

      select {
        width: 100%;
        border: none;
        background: $white;
        padding: 10px 15px;
        font-size: 18px;
        cursor: pointer;

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }
}
