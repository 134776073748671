.promotions-container {
  @include container;
  @include clearfix;

  padding: 80px 40px;
  position: relative;

  @media screen and (min-width: $smallScreen) {
    @include lines-separator;
  }

  @media screen and (max-width: $smallScreen - 1px) {
    background: #F4F4F4;
    margin-bottom: 0;
  }

  // @media screen and (min-width: $smallScreen) {
  //   padding: 80px 80px 0;
  // }

  // @media screen and (min-width: $mediumScreen) {
  //   padding: 80px 0 0;
  // }

  .slick-track {
    padding-bottom: 14px;
    @media screen and (max-width: 767px) {
      padding-bottom: 0;
    }
  }

  .js-promo-carousel {
    @include clearfix;

    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: visible;
    width: 100%;

    .slick-slider,
    .slick-list {
      width: 100%;
      overflow-y: visible;

      // TODO: fix hidden overflow when mobile (shadows not visible)
   
    }
  }

  .promotion-card {
    @media screen and (min-width: 768px) {
      @include dropshadow;
    }

    background: $white;
    width: 100%;
    text-align: center;

    &.put-first {
      order: 1;
    }

    &.featured {
      order: 2;
    }

    &.put-last {
      order: 3;
    }

    @media screen and (min-width: $mediumScreen) {
      $featuredWidthDifference: 23px;

      width: calc((100% - #{$featuredWidthDifference}) /3);

      &.featured {
        width: calc((100% / 3) + #{$featuredWidthDifference});
        margin-top: -17px;

        // offset stretch
        margin-bottom: -17px;
        z-index: 1;
      }
    }

    .img-wrapper {
      display: block;
      position: relative;
      overflow: hidden;

      .promotion-img {
        width: 100%;
        transform: scale(1, 1);
        transition: all 1s cubic-bezier(0, 0, 0.175, 1);
      }

      &:hover {
        .promotion-img {
          transform: scale(1.1, 1.1);
        }
      }
    }

    .promotion-info {
      padding: 40px;

      .title-3 {
        margin: 0 0 8px;
      }

      .promotion-text {
        font-size: 18px;
        line-height: 24px;
        margin: 0 0 30px;
      }

      .button {
        margin: 0;
      }
    }
  }

  .nav-arrows {
    top: 48%;

    @media screen and (min-width: $smallScreen) {
      top: 50%;
    }

    @media screen and (min-width: $mediumScreen) {
      display: none;
    }
  }
}

.news-container {
  @include container;
  @include clearfix;

  @media screen and (min-width: 768px) {
    @include lines-separator;
  }

  display: flex;
  padding: 80px 40px;
  flex-direction: column;

  @media screen and (min-width: $mediumScreen) {
    padding: 80px 0;
    flex-direction: row;
  }

  .news-visual {
    min-height: 300px;
    width: 100%;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;

    @media screen and (min-width: $mediumScreen) {
      width: 50%;
    }
  }

  .news-content {
    width: 100%;

    @media screen and (min-width: $mediumScreen) {
      width: 50%;
    }

    .news-wrapper {
      @include dropshadow;

      /* HACK: Why do we need this? Can't find the grey background container... */
      background: $white;
      padding: 40px;

      @media screen and (min-width: $smallScreen) {
        padding: 50px;
      }

      @media screen and (min-width: $largeScreen) {
        padding: 80px 40px;
      }

      .title-3 {
        line-height: 26px;
      }
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}