* {
  box-sizing: border-box;
  font-family: $mainFont;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.45px;
  color: $black;
  background: $white;

  .overlay-div {
    position: fixed;
    display: block;
    height: 100%;
    width: 100%;
    max-height: 100vh;
    max-width: 100vw;
    top: 0;
    left: 0;
    opacity: 0;
    background: rgba(0, 0, 0, 0.7);
    content: '';
    z-index: -1;
    transition: opacity 0.3s ease-in-out;
  }

  &.overlay {
    overflow: hidden;

    .overlay-div {
      opacity: 1;
      z-index: 99;
    }
  }
}

div,
header,
nav,
aside,
section,
article {
  margin: 0;
  padding: 0;
}

textarea,
input,
select,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1em;

  &:focus {
    outline: none;
  }
}

img {
  vertical-align: middle;
  border: none;
}

p, a, span, h1, h2, h3, h4, h5, li {
  &.neutra-bold {
    font-family: $boldFont;
  }

  &.neutra-demi {
    font-family: $demiFont;
  }

  &.lato-font {
    font-family: $latoFont;
  }
}

strong {
  font-family: $demiFont;
}

p {
  line-height: 22px;
  margin: 15px 0;

  &.single-space {
    margin: 0;
  }
}

a {
  line-height: 22px;
  border: none;
  color: $black;
  text-decoration: none;
  transition: color 0.3s ease-in-out;

  &:hover, &.current, &.yellow-link {
    color: $linkColor;
  }

  &.yellow-link {
    &:hover {
      color: darken($linkColor, 10%);
    }
  }
}

h1, h2, h3, .title-1, .title-2, .title-3 {
  font-family: $demiFont;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0;
}

.title-1, h1 {
  @include font-size(32px);

  line-height: 36px;
}

.title-2, h2 {
  @include font-size(24px);

  line-height: 28px;
}

.title-3, h3 {
  @include font-size(20px);

  line-height: 22px;
}

.fleft {
  float: left;
}

.fright {
  float: right;
}

.centered {
  text-align: center;
}



.clearfix {
  overflow: hidden;
  clear: both;

  &.absolute-clearfix {
    overflow: visible;

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
}

/////////////////////////////////
// TODO: ELIMINATE CLASSES BELOW
// AFTER MIXINS IMPLEMENTATIONS
/////////////////////////////////

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.box-shadow {
  @include dropshadow;
}

.main-container {
  background: $lightGrey;
  padding-top: 78px;

  @media screen and (min-width: $mediumScreen) {
    padding-top: 123px;
  }

  &.white-bg {
    background: $white;
  }

  a {
    font-family: $latoFont;
  }
}

.vertical-align-wrapper {
  @include vertical-align;
}

////////////////////
//STICKY FOOTER
////////////////////

.wrapper {
  position: relative;

  @media screen and (min-width: $smallScreen) {
    min-height: 100%;
    margin: 0 auto -630px;
  }
}

@media screen and (min-width: $smallScreen) {
  footer, .push {
    height: 630px;
  }
}

////////////////////
// HEADER
////////////////////

header {}

////////////////////
// FOOTER
////////////////////

footer {
  background: $white;
}
