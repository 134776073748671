.programmation-categories-holder {
  display: inline-block;
  text-align: center;
  margin: 12px 0;
  appearance: none;

  .button-holder {
    display: inline-block;
    text-align: center;
    margin: 12px 0;
    appearance: none;

    &:first-of-type, &:nth-child(odd) {
      margin-right: 20px;
    }

    @media screen and (min-width: 320px) {
      margin-right: 20px;
    }

    @media screen and (min-width: $smallScreen) {
      width: 65px;
    }

    &:last-of-type {
      margin-right: 0;
    }

    button {
      cursor: pointer;
    }

    label {
      display: none;
      color: $black;
      margin-top: 10px;

      @media screen and (min-width: $smallScreen) {
        display: block;
      }
    }
  }

  .btn-wrapper {
    position: relative;
    width: 38px;
    height: 38px;
    margin: auto;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 0.7;
    }

    .cat-holder {
      position: absolute;
      top: 0px;
      left: 0px;
      opacity: 0;
      z-index: 999;
    }

    .btn-overlay {
      height: 100%;
      width: 100%;
      opacity: 1;
      z-index: 1;
    }
  }
}