.button{

  display: block;
  width: 100%;
  padding: 12px 25px;
  margin: 0px 12px 10px 0;
  text-transform: uppercase;
  color: $white;
  background: $cyan;
  border-radius: 2px;
  transition: background 0.3s ease-in-out;
  cursor: pointer;

  &.grey{

    background: $darkGrey;
  }

  &.pdf{

    background-repeat: no-repeat;
    background-image: url('../images/pdf-picto.svg');
    background-position: center right 15px;
    padding-right: 60px;
  }

  @media screen and (min-width: $smallScreen){

    display:inline-block;
    width: auto;
  }

  &:hover{

    color: $white;
    background: darken($cyan, 8%);

    &.grey{

      background: lighten($black, 20%)
    }

    &.pdf{

      background-repeat: no-repeat;
      background-image: url('../images/pdf-picto.svg');
      background-position: center right 15px;
    }
  }
}

.half{

  width: 100%;

  @media screen and (min-width: $smallScreen){

    width: 50%;
    float:left;
  }
}


.generic-block:not(:first-of-type) h2 {
  @extend .title-3;
}
