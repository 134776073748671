.contact-container {

  position: relative;

  .content-wrapper{

    width: 100%;
    text-align:right;

    @media screen and (min-width: $smallScreen){

      width: 50%;
      float:left;
    }


    .contact-content {

      display: inline-block;
      text-align:left;
      padding:40px 20px;

      @media screen and (min-width: $largeScreen){

        max-width: 600px;
        padding: 60px 60px 20px 0px;
      }


      .title-1{

        margin-bottom: 50px;
        @include font-size(36px);
        line-height: 40px;
      }

      .title-2{

        @include font-size(30px);
        line-height: 34px;
        margin-bottom: 20px;
      }

      .title-3{

        @include font-size(20px);
        margin-top: 30px;

        &.no-margin{

          margin:20px 0 0;
        }
      }

      section{

        margin-bottom: 40px;

        p{

          max-width: 400px;

          span{

            text-decoration: underline;
            color: $linkColor;
          }

          &.no-margin {

            margin: 0 0 30px;

          }
        }
      }
    }


    .admin-content {

      background: $lightGrey;

      .admin-contact{

        margin-bottom: 20px;

        &:first-child {

          margin-top: 30px;

        }

        .no-margin{

          margin:0;
        }
      }
    }
  }


  .contact-map {

    width: 100%;
    height: 300px;

    @media screen and (min-width:$smallScreen){

      height: calc(100vh - 123px);
    }

    img {

      width: 100%;
    }

    @media screen and (min-width: $smallScreen){

      float: right;
      width: 50%;
    }
  }

  iframe{

    max-height: calc(100vh - 123px);
  }
}
