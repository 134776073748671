$roomsBreakpoint: 768px;

.rooms-selection-wrapper {
  @include section-padding;

  padding-bottom: 0 !important;

  // HACK shame
  background: $lightGrey;
}

.rooms-selection {
  @include container;

  display: flex;
  flex-direction: row;
  padding-top: 80px;

  @media only screen and (max-width: $roomsBreakpoint) {
    flex-direction: column;
    padding-top: 120px;
  }

  .generic-content {
    section {
      margin: 0;
      padding: 0;
    }
  }
}

.room-selection {
  position: relative;
  width: calc((100% - (60px * 2) ) / 3);
  margin-right: 60px;

  .room-description {
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  &:nth-child(3n) {
    margin-right: 0;
  }

  &:hover,
  &:focus {
    cursor: pointer;
  }

  // Animation (shows selected room)
  &:before {
    content: '';
    border-width: 0 0 0 0;
    background: transparent;
    transition: border-width .5s ease-in-out;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &.selected {
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 22.5px 23px 22.5px;
      border-color: transparent transparent $cyan transparent;
    }
  }

  @media only screen and (max-width: $roomsBreakpoint) {
    width: 100%;
  }
}

.room-image-overlay {
  position: relative;
}

.room-image {
  width: 100%;

  /* TODO: temporary */
}

.room-name {
  @include dropshadow;

  width: 85%;
  max-width: 400px;
  height: 70px;
  position: absolute;
  bottom: 0 - 70px / 2;
  transform: translateX(-50%);
  left: 50%;
  padding: 12px 20px;
  background: $white;
  text-align: center;

  .title-3 {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.room-tagline {
  margin-top: 47px;
  margin-bottom: 64px;

  .title5 {
    text-align: center;
    font-family: $mainFont;
    font-size: 18px;
    line-height: 24px;
    padding-top: 14px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 48px;
    margin-bottom: 32px;
  }
}

.rooms-information {
  @include container;
  @include section-padding;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.room-information {
  display: flex;
  flex-direction: column;

  /* TODO :HACK (arbitrary height that's too high, intentionally) :(
https://css-tricks.com/using-css-transitions-auto-dimensions/) */
  max-height: 2000px;
  opacity: 1;
  overflow: hidden;
  transition: all 1s ease-in-out;

  &:not(.selected) {
    max-height: 0;
    opacity: 0;
  }
}

.room-information-name-title {
  margin-bottom: 40px;
}

.room-information-services-title {
  margin-bottom: 38px;
}

.room-services {
  @include basic-list;

  padding-bottom: 80px;
}

.room-information-technical-title {
  margin-bottom: 38px;
}

.room-technical-information {}

.room-corpdoc-link {
  @include btn-download;
}