.programmation-cta {
  @include container;
  @include lines-separator;
  overflow: visible;

  padding: 80px 20px;
  background: url("../images/cta-bg.png") $white no-repeat right;
  background-size: cover;
  text-align: center;

  @media screen and (min-width: $smallScreen) {
    background-size: contain;
  }

  .title-1 {
    @include font-size(30px);
  }

  p {
    font-size: 18px;
    margin: 20px auto 40px;
    max-width: 780px;
  }

  .button {
    font-size: 14px;
    margin: 0;
  }

}