.hero-container {
  background: url("../images/bg.svg") center center no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: middle;

  .hero-title {
    text-align: center;
    color: $white;
  }

  @media screen and (min-width: $mediumScreen) {
    overflow: hidden;
    padding: 105px 0 85px;
  }

  .hero-contents {
    max-height: 1000px;
    overflow: hidden;

    @media screen and (min-width: $mediumScreen) {
      padding: 20px 0 20px 20px;
      max-height: 500px;
    }

    .slick-list {
      overflow: visible;
    }

    .hero-slides {
      position: relative;
      pointer-events: none;

      .js-featured {
        width: calc(100% - 65px);
      }

      .hero-img-wrapper {
        position: relative;

        .img-wrapper {
          position: relative;
          display: inline-block;
          overflow: hidden;
          pointer-events: auto;
          border: 2px dotted $cyan;
          max-height: 458px;

          .hero-img {
            width: 100%;

            @media screen and (min-width: $mediumScreen) {
              border-right: 0;
              max-width: 740px;
              transform: scale(1, 1);
              transition: all 1s cubic-bezier(0, 0, 0.175, 1);
            }
          }

          &:hover {
            .hero-img {
              transform: scale(1.1, 1.1);
            }
          }
        }

        .show-date {
          background: rgba($black, 0.6);
          padding: 10px;
          position: absolute;
          display: block;
          top: 20px;
          left: 0;
          color: $white;
          text-transform: uppercase;
          margin: 0;
          z-index: 1;

          @media screen and (min-width: $largeScreen) {
            left: -20px;
            background: $black;
          }
        }
      }

      .hero-info {
        position: relative;
        background: $white;
        padding: 20px;
        margin: -60px 20px 0;

        @media screen and (min-width: $mediumScreen) {
          position: absolute;
          top: -18px;
          right: 60px;
          max-width: 410px;
          width: 100%;
          height: 495px;
          padding: 40px;
          margin: 0;
        }

        .title-1 {
          margin-bottom: 15px;

          @media screen and (min-width: 480px) {
            margin-bottom: 30px;
          }
        }

        .show-details {
          position: relative;
          bottom: 0;
          margin-top: 40px;

          @media screen and (min-width: $mediumScreen) {
            position: absolute;
            bottom: 20px;
            margin-top: 78px;
            width: calc(100% - 40px);
          }

          .show-category {
            position: absolute;
            top: 2px;
            right: 0;

            @media screen and (min-width: $mediumScreen) {
              bottom: 10px;
              right: 20px;
              top: auto;
            }
          }

          .button {
            pointer-events: auto;
          }
        }

        .show-status {
          &.postponed, &.sold-out {
            color: $red;
          }

          &.initial-date {
            color: $blue;
          }
        }
      }
    }
  }

  .hero-nav {
    position: absolute;
    bottom: -36px;
    background: white;
    height: 60px;
    right: 40px;
    width: calc(100% - 80px);

    @media screen and (min-width: 480px) {
      bottoM: -60px;
    }

    @media screen and (min-width: $mediumScreen) {
      top: 20px;
      right: 0;
      height: 100%;
      max-height: 460px;
      width: auto;
    }

    .next-arrow, .prev-arrow {
      float: right;
      position: relative;
      width: 50%;
      height: 100%;
      cursor: pointer;
      box-shadow: inset 0px 15px 15px -15px rgba(0, 0, 0, 0.22);
      transition: background 0.3s ease-in-out;

      @media screen and (min-width: $mediumScreen) {
        float: none;
        height: 50%;
        width: 60px;
        box-shadow: inset 15px 0px 15px -15px rgba(0, 0, 0, 0.22);
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 60px;
        background: url("../images/arrow.svg") no-repeat center;

        @media screen and (min-width: $mediumScreen) {
          height: 30px;
          left: 20px;
          top: 94px;
          width: 16px;
        }
      }
    }

    .next-arrow {
      background: $white;

      &:hover {
        background: darken($white, 10%);
      }
    }

    .prev-arrow {
      background: $lightGrey;

      &:hover {
        background: darken($lightGrey, 10%);
      }

      &:before {
        transform: rotate(180deg);
      }
    }
  }

  //COMMON HEROES
  &.common-hero {
    background-image: url("../images/bg-hero-common.jpg");
    background-position: center;
    text-align: center;
    height: 290px;

    &.location {
      @media screen and (max-width: $smallScreen) {
        height: 350px;
      }
    }

    &.programmation {
      height: 400px;

      @media screen and (max-width: $smallScreen) {
        height: 370px;

        .hero-contents {
          margin-top: -130px;
        }
      }
    }

    @media screen and (max-width: $mediumScreen) {
      &.generic-hero {
        min-height: 200px;
      }
    }

    @media screen and (max-width: $smallScreen) {
      height: 328px;
    }
  }

  //SHOW HEROES
  &.show-hero {
    background: $black;
    padding-bottom: 23%;
    text-align: center;

    @media screen and (max-width: 1023px) {
      padding-top: 40px;
    }

    .hero-title {
      @include font-size(36px);
    }

    .show-name {
      color: $white;

      @include font-size(22px);

      margin: 22px 0;
      text-transform: none;

      @media screen and (min-width: $smallScreen) {}
    }
  }
}
