.hero-bottom-card {
  @include container;
  @include dropshadow;
  @include section-padding;

  padding: 31px 52px;
  background: #fff;
  text-align: center;
  z-index: 3;

  @media screen and (max-width: 769px) {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 90%;
  }

  p, a, .button {
    .generic-content & {
      font-size: 16px;      /* HACK! ... */
    }
  }

  a:not(.button) {
    color: $orange;
    font-family: $boldFont;
    text-decoration: none;

    &:hover,
    &:focus {
      color: darken($orange, 8%);
    }
  }
}
