.newsletter-subscribe {
  @include clearfix;
  @include section-padding;

  text-align: center;
  background: $lightGrey;

  .title-2 {
    @include container;
  }

  .newsletter-form {
    @include container;

    display: flex;
    max-width: 668px;
    margin: 40px auto 0;

    @media screen and (max-width: $smallScreen) {
      flex-direction: column;
    }
  }

  .newsletter-input {
    width: calc((100% / 3) * 2);
    max-width: 530px;
    margin-right: 25px;
    padding-bottom: 5px;
    font-size: 18px;
    border: 0;
    border-bottom: 1px solid $black;
    background: transparent;

    @media screen and (max-width: $smallScreen) {
      width: 100%;
      margin: 26px auto;
    }
  }

  .newsletter-submit {
    max-width: 530px;
    border: 0;
    font-size: 14px;
    padding: 14px 25px 10px;
    margin: auto;
  }
}