.show-main-info {
  // max-width: 1190px;
  width: 100%;
  margin-top: -21%;

  .hero-img {
    border: 2px dotted $cyan;
    width: 100%;
    max-height: none;

    @media screen and (min-width: $mediumScreen) {
      border-right: 0;
      max-width: 800px;
      max-height: 498px;
    }
  }

  .hero-tickets {
    position: relative;
    background: $white;
    padding: 20px 20px 42px;
    margin: -60px 20px 0;
    text-align: center;
    z-index: 1;

    @media screen and (min-width: $mediumScreen) {
      position: absolute;
      top: -22px;
      right: 0;
      width: 410px;
      height: 538px;
      padding: 40px;
      margin: 0;
    }

    .title-1 {
      @include font-size(24px);

      @media screen and (min-width: $smallScreen) {
        @include font-size(28px);
      }
    }

    .tickets-price {
      @include font-size(18px);

      text-transform: uppercase;
      margin-top: 10px;

      span {
        font-family: $latoFont;
        color: $linkColor;
      }

      @media screen and (min-width: $smallScreen) {
        margin-top: 30px;

        @include font-size(20px);
      }
    }

    .gradient-overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      bottom: 20px;
      left: 0;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+73,0.8+100 */
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0.8) 100%);

      /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0.8) 100%);

      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0.8) 100%);

      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ccffffff',GradientType=0 );

      /* IE6-9 */
      pointer-events: none;
      z-index: 1;
    }

    .tickets-wrapper {
      overflow-y: scroll;
      position: relative;

      .tickets-buy {
        margin-top: 12px;
        display: block;

        &:first-child {
          margin-top: 0;
        }

        &:hover,
        &:focus {
          color: $darkGrey;
        }
      }

      @media screen and (min-width: $smallScreen) {
        height: 495px;
        height: calc(100% - 100px);
      }

      .mCSB_dragger_bar {
        width: 8px;
        background: $cyan !important;
      }

      .mCSB_draggerRail {
        width: 8px;
        background: $lightGrey;
      }

      .tickets-holder {
        position: relative;
        text-transform: uppercase;
        width: 100%;
        margin-top: 12px;
        display: flex;
        flex-direction: row;

        &:first-child {
          margin-top: 0;
        }

        &:hover,
        &:focus {
          .tickets-buy {
            // TODO: hack, why !important?
            background: darken($cyan, 10%) !important;

            &:after {
              transform: translateX(8px);
            }
          }
        }

        @media screen and (max-width: $smallScreen) {
          min-height: 100px;
        }

        &.postponed {
          .tickets-info {
            .sold-out {
              margin-top: 6px;
            }
          }
        }

        .tickets-date {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 60px;
          font-size: 14px;
          line-height: 18px;
          padding: 8px;
          color: $white;
          background: $black;
          //min-height:112px;

          p {
            position: relative;
            margin: auto;
            font-family: $latoFont;

            span {
              display: block;
              font-size: 19px;
              line-height: 22px;
            }
          }
        }

        .tickets-info {
          position: relative;
          display: flex;
          flex-direction: column;
          flex: 1 auto;
          background: $lightGrey;
          text-align: left;
          font-size: 14px;

          &.unavailable {
            padding-top: 18px;
          }

          .tickets-hour, .tickets-status {
            display: flex;
            padding: 0 16px 0 0;
            margin: 0;
            flex-direction: row;
            justify-content: flex-start;
            flex: 1 auto;
            align-items: center;

            @media screen and (max-width: $smallScreen) {
              padding-top: 20px;
              justify-content: flex-start;
            }
          }

          .tickets-hour {
            font-size: 18px;
            font-family: $latoFont;
          }

          .tickets-status {
            font-weight: 600;
            justify-content: flex-end;
            margin-top: 4px;      // HACK - FIX ALIGNMENT

            &.initial-date {
              color: $blue;
            }

            &.sold-out {
              color: $orange;
              margin-left: auto;
            }

            &.extra {
              color: $black;
            }

            @media screen and (max-width: $smallScreen) {
              margin-top: 0;
              justify-content: flex-start;

              &.sold-out {
                margin-left: 0;
              }
            }
          }

          .tickets-buy {
            position: relative;
            display: block;
            width: 100%;
            padding: 8px 16px;
            margin: 0;
            background: $cyan;
            color: $white;
            transition: background .3s ease-in-out;
            margin-top: auto;

            &:after {
              content: '';
              display: block;
              position: absolute;
              right: 16px;
              top: 0;
              height: 100%;
              width: 8px;
              background: url("../images/arrow-white.svg") no-repeat center;
              background-size: 100%;
              transition: transform .3s ease-in-out;
            }
          }
        }

        .tickets-info-status {
          display: flex;
          flex-direction: row;
          flex: 1 auto;
          padding:0 20px;

          @media screen and (max-width: $smallScreen) {
            flex-direction: column;
          }
        }

        &.postponed {
          .tickets-date {
            p {
              &:before {
                content: '';
                position: absolute;
                width: 2px;
                height: 100%;
                background: $cyan;
                transform: rotate(-45deg);
                top: 0;
              }
            }
          }

          .tickets-status {
            float: none;

            span {
              font-family: $latoFont;
            }
          }
        }
      }
    }
  }
}

.show-main-content {
  @include container;
  @include clearfix;

  max-width: 1200px;
  padding: 40px 20px 40px;
  background: $white;

  @media screen and (min-width: $smallScreen) {
    padding: 80px 20px 80px;
  }

  @media screen and (min-width: $largeScreen) {
    padding: 80px 0 80px;
  }

  .show-content {
    width: 100%;
    margin-right: 40px;
    color: #000;

    @media screen and (min-width: $smallScreen) {
      width: 65%;

      iframe {
        width: 100%;
      }
    }

    .show-title {
      font-size: 30px;
      margin: 20px 0 0;

      @media screen and (min-width: $smallScreen) {
        margin: 0 0 15px;
      }
    }

    p {
      margin: 30px 0;
    }

    img {
      width: 100%;
      margin-top: 10px;
    }

    .video-wrapper {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 25px;
      height: 0;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .show-general-info {
    margin-top: 40px;
    width: 100%;

    @media screen and (min-width: $smallScreen) {
      margin-top: 0;
      width: calc(35% - 40px);
    }

    > div {
      border-bottom: 1px $black solid;
      padding: 15px 0;

      &:last-child {
        border: none;
      }
    }

    .title-3 {
      font-weight: 500;
      margin: 15px 0;
    }

    p:not(.title-3) {
      font-size: 14px;
    }

    .phone-tickets {
      padding: 0 10px 15px;
      text-align: center;

      .title-3 {
        background: $lightGrey;
        padding: 18px 20px;
        margin: 0 0 15px;

        span {
          color: $linkColor;
          font-family: $latoFont;
        }
      }
    }

    .show-promotions,
    .show-menu {
      text-align: center;

      a {
        display: block;
        margin: 16px 0;
      }
    }

    .show-category {
      .cat-holder {
        display: inline-block;

        .label {
          padding-left: 50px;

          // height of icon corresponding to category
          line-height: 38px;
        }
      }
    }

    .show-socials {
      .social-holder {
        a {
          display: inline-block;
          width: 38px;
          height: 38px;
          margin-right: 15px;
          background-image: url("../images/social-sprite.svg");
          background-repeat: no-repeat;
          opacity: 0.7;
          transition: all 0.3s ease-in-out;

          &:hover {
            opacity: 1;
          }

          &:last-child {
            margin-right: 0;
          }

          &.social-website {
            background-position: 0 0;
          }

          &.social-facebook {
            background-position: -53px 0;
          }

          &.social-twitter {
            background-position: -106px 0;
          }

          &.social-instagram {
            background-position: -159px 0;
          }

          &.social-snapchat {
            background-position: -212px 0;
          }

          &.social-youtube {
            background-position: -265px 0;
          }
        }
      }
    }
  }
}
